import { t } from "@lingui/macro";
import jsPDF from "jspdf";
import {
  taxonomyCheckErrorShowcase,
  XMLCheckErrorShowcase,
  entryPointsCheckErrorShowcase,
  xHTMLCheckErrorShowcase,
  linkBaseCheckErrorShowcase,
  importErrors,
} from "./constants";
import { notoSansAsString } from "../style/fonts/notoSansAsString";

const checkPage = (doc: jsPDF, yCounter: number, limit = 250): number => {
  if (yCounter > limit) {
    doc.addPage();
    yCounter = 10;
  }
  return yCounter;
};

const addSectionHeader = (
  doc: jsPDF,
  rootElem: HTMLElement,
  selector: string,
  yCounter: number
): number => {
  const header = rootElem.querySelector(selector) as HTMLElement;
  if (header) {
    doc.setFontSize(20);
    doc.text(header?.innerText || "", 10, yCounter);
    yCounter += 8;
    doc.setFontSize(10);
    doc.setLineWidth(0.1);
    doc.line(10, yCounter - 5, 200, yCounter - 5);
  }
  return yCounter;
};

const addSummarySection = (
  doc: jsPDF,
  rootElem: HTMLElement,
  dataKey: string,
  yCounter: number
): number => {
  const keys = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="${dataKey}key"]`)
  ) as HTMLElement[];
  const values = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="${dataKey}value"]`)
  ) as HTMLElement[];
  if (keys.length === 0) {
    return yCounter;
  } else {
    const headerSelector = `[data-downloadaspdf="${dataKey}header"]`;
    yCounter = addSectionHeader(doc, rootElem, headerSelector, yCounter);
    const headerHeight = 18;
    const contentHeight = values.length * 5;
    doc.rect(5, yCounter - 16, 200, contentHeight + headerHeight);
    const startX = 5;
    if (contentHeight > 0) {
      doc.setLineWidth(0.1);
    }
    for (let index = 0; index < keys.length; index++) {
      const rowText = `${keys[index].innerText} ${values[index]?.innerText || ""}`;
      const color = values[index]?.innerText.includes(t`Error`)
        ? "red"
        : values[index]?.innerText.includes(t`Warning`)
          ? "orange"
          : "black";
      if (color) {
        doc.setTextColor(color);
      }
      doc.text(rowText, startX + 5, yCounter + 5);
      yCounter += 5;
      yCounter = checkPage(doc, yCounter);
    }
    yCounter += 15;
    doc.setTextColor("black");
    return yCounter;
  }
};

const addValidationSection = (
  doc: jsPDF,
  rootElem: HTMLElement,
  dataKey: string,
  yCounter: number
): number => {
  const keys = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="${dataKey}key"]`)
  ) as HTMLElement[];
  const values = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="${dataKey}value"]`)
  ) as HTMLElement[];
  const andValue = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="${dataKey}valueAnd"]`)
  ) as HTMLElement[];
  const valueSecond = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="${dataKey}valueSecond"]`)
  ) as HTMLElement[];
  if (keys.length === 0) {
    return yCounter;
  } else {
    const headerSelector = `[data-downloadaspdf="${dataKey}header"]`;
    yCounter = addSectionHeader(doc, rootElem, headerSelector, yCounter);
    const headerHeight = 18;
    const contentHeight = values.length * 5;
    doc.rect(5, yCounter - 16, 200, contentHeight + headerHeight);
    const startX = 5;
    if (contentHeight > 0) {
      doc.setLineWidth(0.1);
    }
    for (let index = 0; index < keys.length; index++) {
      const rowText = `${keys[index].innerText} ${values[index]?.innerText} ${andValue[index]?.innerText || ""} ${valueSecond[index]?.innerText || ""}`;
      const color = values[index]?.innerText.includes(t`Error`)
        ? "red"
        : values[index]?.innerText.includes(t`Warning`)
          ? "orange"
          : "black";
      if (color) {
        doc.setTextColor(color);
      }
      doc.text(rowText, startX + 5, yCounter + 5);
      yCounter += 5;
      yCounter = checkPage(doc, yCounter);
    }
    yCounter += 15;
    doc.setTextColor("black");
    return yCounter;
  }
};

const addZipContentTableSection = (
  doc: jsPDF,
  rootElem: HTMLElement,
  yCounter: number
): number => {
  yCounter = checkPage(doc, yCounter);
  const headerSelector = `[data-downloadaspdf="zipcontentheader"]`;
  yCounter = addSectionHeader(doc, rootElem, headerSelector, yCounter + 10);
  const columnHeaders = Array.from(
    rootElem.querySelectorAll(
      `[data-downloadaspdf="zipcontenttablecolumnheader"]`
    )
  ) as HTMLElement[];

  const xPositions = columnHeaders.map((_, index, array) => {
    if (index === array.length - 1) {
      return 10 + 60 * index;
    } else {
      return 10 + 80 * index;
    }
  });

  for (let index = 0; index < columnHeaders.length; index++) {
    doc.text(columnHeaders[index].innerText, xPositions[index], yCounter);
  }

  yCounter += 5;
  const fileNameElems = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="zipcontentrowfilename"]`)
  ) as HTMLElement[];
  const fileTypeElems = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="zipcontentrowfiletype"]`)
  ) as HTMLElement[];
  const fileHashElems = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="zipcontentrowfilehash"]`)
  ) as HTMLElement[];

  const headerHeight = 22;
  const contentHeight = fileNameElems.length * 5;
  doc.rect(5, yCounter - 22, 200, contentHeight + headerHeight);

  const fontSize = 8; // set the desired font size

  doc.setFontSize(fontSize);

  let xCounter = 10; // set initial x position

  for (let index = 0; index < fileNameElems.length; index++) {
    doc.text(fileNameElems[index].innerText, xCounter, yCounter);
    xCounter += 80; // add margin between file name and file type
    doc.text(fileTypeElems[index]?.innerText || "Folder", xCounter, yCounter);
    xCounter += 35; // add margin between file type and file hash
    doc.text(fileHashElems[index].innerText, xCounter, yCounter);
    xCounter = 10; // reset x position for next row
    yCounter += 5; // add vertical spacing between rows
  }

  return yCounter;
};

const addErrorShowcaseTableSection = (
  doc: jsPDF,
  rootElem: HTMLElement,
  key: string,
  yCounter: number,
  originalColor: string
): number => {
  yCounter = checkPage(doc, yCounter);
  const sectionKey = dataKey(key);

  const levelElems = Array.from(rootElem.querySelectorAll(`[data-downloadaspdf="${sectionKey}severity"]`)) as HTMLElement[];
  const messageElems = Array.from(rootElem.querySelectorAll(`[data-downloadaspdf="${sectionKey}message"]`)) as HTMLElement[];
  const linkElems = Array.from(rootElem.querySelectorAll(`[data-downloadaspdf="${sectionKey}link"]`)) as HTMLElement[];

  const headerSelector = `[data-downloadaspdf="${sectionKey}header"]`;
  yCounter = addSectionHeader(doc, rootElem, headerSelector, yCounter);

  const columnHeaders = Array.from(rootElem.querySelectorAll(`[data-downloadaspdf="${sectionKey}columnheader"]`)) as HTMLElement[];
  if (columnHeaders.length > 2) {
    doc.text(columnHeaders[0].innerText, 10, yCounter);
    doc.text(columnHeaders[1].innerText, 40, yCounter);
    doc.text(columnHeaders[2].innerText, 130, yCounter);
    yCounter += 5;
  }

  if (levelElems.length > 0 && messageElems.length > 0 && linkElems.length > 0) {
    for (let index = 0; index < levelElems.length; index++) {
      const severityElem = levelElems[index];
      const messageElem = messageElems[index];
      const linkElem = linkElems[index];

      if (!severityElem || !messageElem || !linkElem) continue;

      const color = severityElem.dataset.cellcolor;
      if (color) {
        doc.setTextColor(color);
      }

      const severityText = severityElem.innerText || '';
      doc.text(severityText, 10, yCounter);

      const messageText = messageElem.innerText || '';
      const formattedMessageText = doc.splitTextToSize(messageText, 110);
      let lines = formattedMessageText.length;

      const linkText = linkElem.innerText || '';
      const formattedLinkText = doc.splitTextToSize(linkText, 50);
      lines = Math.max(lines, formattedLinkText.length);

      for (let i = 0; i < lines; i++) {
        if (yCounter + 6 > doc.internal.pageSize.height - 20) {
          doc.addPage();
          yCounter = 20;

          if (columnHeaders.length > 2) {
            doc.text(columnHeaders[0].innerText, 10, yCounter);
            doc.text(columnHeaders[1].innerText, 40, yCounter);
            doc.text(columnHeaders[2].innerText, 130, yCounter);
            yCounter += 5;
          }
        }
        doc.setFontSize(8); // Set smaller font size
        if (formattedMessageText[i]) {
          doc.text(formattedMessageText[i], 40, yCounter);
        }
        doc.setFontSize(10); // Reset to default font size
        if (formattedLinkText[i]) {
          doc.text(formattedLinkText[i], 130, yCounter);
        }
        yCounter += 6;
        yCounter = checkPage(doc, yCounter);
      }
    }
  }

  doc.setTextColor(originalColor);

  yCounter += 15;
  return yCounter;
};


const addValidationLogSection = (
  doc: jsPDF,
  rootElem: HTMLElement,
  yCounter: number
): number => {
  yCounter += 8;
  yCounter = checkPage(doc, yCounter);
  const headerSelector = `[data-downloadaspdf="validationlogheader"]`;
  yCounter = addSectionHeader(doc, rootElem, headerSelector, yCounter);
  const logEntryElems = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="validationlogentry"]`)
  ) as HTMLElement[];
  for (const entryElem of logEntryElems) {
    const formattedText = doc.splitTextToSize(entryElem?.innerText || "", 180);
    doc.text(formattedText, 10, yCounter);
    yCounter += Array.isArray(formattedText) ? formattedText.length * 6 : 25;
    yCounter = checkPage(doc, yCounter);
  }
  return yCounter;
};

const generatePdfContent = (doc: jsPDF, rootId: string): void => {
  doc.setLineWidth(140);

  const rootElem = document.getElementById(rootId);
  if (rootElem) {
    let yCounter = 10;
    yCounter = addValidationSection(
      doc,
      rootElem,
      "validationsummary",
      yCounter
    );
    yCounter = addSummarySection(doc, rootElem, "generalinformation", yCounter);
    yCounter = addSummarySection(doc, rootElem, "taxonomypackage", yCounter);
    yCounter = addZipContentTableSection(doc, rootElem, yCounter);
    const originalColor = doc.getTextColor();
    doc.addPage();
    yCounter = 10;
    yCounter = addErrorShowcaseTableSection(
      doc,
      rootElem,
      importErrors,
      yCounter,
      originalColor
    );
    yCounter = addErrorShowcaseTableSection(
      doc,
      rootElem,
      taxonomyCheckErrorShowcase,
      yCounter,
      originalColor
    );

    yCounter = addErrorShowcaseTableSection(
      doc,
      rootElem,
      XMLCheckErrorShowcase,
      yCounter,
      originalColor
    );

    yCounter = addErrorShowcaseTableSection(
      doc,
      rootElem,
      entryPointsCheckErrorShowcase,
      yCounter,
      originalColor
    );

    yCounter = addErrorShowcaseTableSection(
      doc,
      rootElem,
      xHTMLCheckErrorShowcase,
      yCounter,
      originalColor
    );

    yCounter = addErrorShowcaseTableSection(
      doc,
      rootElem,
      linkBaseCheckErrorShowcase,
      yCounter,
      originalColor
    );
    addValidationLogSection(doc, rootElem, yCounter);
  }
};

export const downloadPageAsPdf = (rootId: string, fileName: string): void => {
  const doc = new jsPDF();
  doc.addFileToVFS('noto-sans-v27-latin-500-normal.ttf', notoSansAsString);
  doc.addFont('noto-sans-v27-latin-500-normal.ttf', 'noto-sans-v27-latin-500', 'normal');
  doc.setFont("noto-sans-v27-latin-500");
  generatePdfContent(doc, rootId);
  doc.save(fileName);
};

export const getPageAsPdfString = (rootId: string): string => {
  const doc = new jsPDF();
  generatePdfContent(doc, rootId);
  return doc.output();
};

export const dataKey = (input: string) =>
  input.toLowerCase().replaceAll(" ", "");
