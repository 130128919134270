import { t } from "@lingui/macro";
import { Collapse, Box, Alert, Typography } from "@mui/material";
import { emailprefix, emailSuffix } from "../../helpers/constants";
import { LinkMailto } from "./mailToParsePort";

export const ErrorAlertCard = (
  showErrorMessage: boolean,
  whatErrorMessage:
    | "Error403"
    | "Error"
    | "ProcessFiles"
    | "dialog"
    | "notValid"
    | "validationError",
  id?: string
) => {
  return (
    <Collapse in={showErrorMessage} sx={{ mb: 1 }}>
      <Box>
        <Alert
          severity="error"
          sx={{
            margin: "auto",
          }}>
          <Typography
            fontSize={14}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}>
            {whatErrorMessage === "Error403" && (
              <>
                {t`You do not have permission to peform this action. `}{" "}
                {t`Please contact`}{" "}
                <LinkMailto
                  label={t`ParsePort support`}
                  mailto={"mailto:" + emailprefix + emailSuffix}
                />{" "}
                {t`for assistance.`}
              </>
            )}
            {whatErrorMessage === "Error" && (
              <>
                {t`The uploaded file is not a valid reporting package. `}
                {t`Please contact`}{" "}
                <LinkMailto
                  label={t`ParsePort support`}
                  mailto={"mailto:" + emailprefix + emailSuffix}
                />{" "}
              </>
            )}
            {whatErrorMessage === "ProcessFiles" && (
              <>
                {t`An error occurred while processing your files.`}{" "}
                {t`Please contact`}{" "}
                <LinkMailto
                  label={t`ParsePort support`}
                  mailto={"mailto:" + emailprefix + emailSuffix}
                />{" "}
                {t`for assistance. `}
                {id && (
                  <>
                    <br />
                    <Box sx={{ textAlign: "center" }}>
                  {t`Conversion Id `} <b>{id}</b>
                </Box>
                  </>
                )}
              </>
            )}
            {whatErrorMessage === "dialog" && (
              <>
                {t`An error occurred while processing your files, which may affect how your report is displayed.`}
              </>
            )}
            {whatErrorMessage === "notValid" && (
              <>
                {t`The uploaded file is not a valid reporting package. Please contact`}{" "}
                <LinkMailto
                  label={t`ParsePort support`}
                  mailto={"mailto:" + emailprefix + emailSuffix}
                />{" "}
                {t`for assistance. `}
                {id && (
                  <>
                    <br />
                    <Box sx={{ textAlign: "center" }}>
                      {t`Conversion Id `} <b>{id}</b>
                    </Box>
                  </>
                )}
              </>
            )}
            {whatErrorMessage === "validationError" && (
              <>
                {t`An error occurred while validating your files.`}{" "}
                {t`Please contact`}{" "}
                <LinkMailto
                  label={t`ParsePort support`}
                  mailto={"mailto:" + emailprefix + emailSuffix}
                />{" "}
                {t`for assistance.`}
                <br />
                <Box sx={{ textAlign: "center" }}>
                  {t`Conversion Id `} <b>{id}</b>
                </Box>
              </>
            )}
          </Typography>
        </Alert>
      </Box>
    </Collapse>
  );
};
